
// Import the plugin style.
//
@import "~@fortawesome/fontawesome-free/css/fontawesome";


// Brands
//
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url('#{$fonts-dir}fa-brands-400.eot');
  src: url('#{$fonts-dir}fa-brands-400.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-dir}fa-brands-400.woff2') format('woff2'),
       url('#{$fonts-dir}fa-brands-400.woff') format('woff'),
       url('#{$fonts-dir}fa-brands-400.ttf') format('truetype'),
       url('#{$fonts-dir}fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

// Regular
//
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  src: url('#{$fonts-dir}fa-regular-400.eot');
  src: url('#{$fonts-dir}fa-regular-400.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-dir}fa-regular-400.woff2') format('woff2'),
       url('#{$fonts-dir}fa-regular-400.woff') format('woff'),
       url('#{$fonts-dir}fa-regular-400.ttf') format('truetype'),
       url('#{$fonts-dir}fa-regular-400.svg#fontawesome') format('svg');
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

// Solid
//
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  src: url('#{$fonts-dir}fa-solid-900.eot');
  src: url('#{$fonts-dir}fa-solid-900.eot?#iefix') format('embedded-opentype'),
       url('#{$fonts-dir}fa-solid-900.woff2') format('woff2'),
       url('#{$fonts-dir}fa-solid-900.woff') format('woff'),
       url('#{$fonts-dir}fa-solid-900.ttf') format('truetype'),
       url('#{$fonts-dir}fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
